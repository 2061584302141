export default function Footer() {
  return (
    <footer style={{ backgroundColor: "#000000", color: "#FFFFFF" }}>
      <hr />
      <div className="p-3">
        <p>Copyright &copy; Faith Castillo 2025</p>
        <p>
          Hosted by <a href="https://nws.nickorlow.com">NWS</a>
        </p>
      </div>
    </footer>
  );
}
