export default function ArtistStatement() {
  return (
    <div className="p-10">
    <p>Not Found</p>
    {/*     <h1 className="font-bold text-4xl mb-10">Artist Statement</h1>
      <p className="mb-5">
I have always been drawn to the unseen forces that shape our existence - spiritual energies that move through us, often without our conscious awareness. Growing up in a Hispanic Roman Catholic household, I found solace in the communities of my faith, specifically in the reverence for Our Lady of Guadalupe, whose image embodies both motherly protection and divine strength. However my relationship with the church has been complicated. The teachings, especially regarding women, often left me struggling with catholic guilt and internalized sense of shame. I struggled with the idea of my own worth, my place in a world that seemed to view me through a lens of purity and moral restriction. These conflicting forces, beauty and devotion of my upbringing alongside the oppressive ideals I encountered - shaped my identity as both a woman and an artist.
        </p>

      <p className="mb-5">
I am the first in my family to attend college and pursue the arts, I carry the weight of their sacrifices with immense pride. My greatest goal is to give back a home for my parents and send them into a well-earned retirement. This drive is rooted in my belief that through art, we can transcend adversity and find a deeper sense of purpose. Through my work, I invite viewers to pause, reflect, and connect with the sacred threads that bind us to one another, to the Earth, and  to the divine.
        </p>

      <p className="mb-5">
Understanding myself and knowing what I value and what I love all was seeded and stemmed from unbearable pain, a willingness to suffer , the act of selfless love as what is often portrayed in god. I see him in my parents , I see my father working multiple dead end jobs keeping us barely afloat trying to offer us the entire extent of the world he could offer and my mother battling the physical limitations in her vessel while holding my hand and keeping me healthy, these experiences are woven into my soul and into my work- where I am able to explore the limitless connection of the body, spirit and the selfless love that watched over. 
        </p>

      <p className="mb-5">
I am fascinated by the idea of the body as a vessel for the soul, with veins symbolizing the unseen forces that guide us. The pulse of life runs through both our bodies and the Earth, connecting us to the natural and divine energies that sustain us our very morality - The Threads of Flesh and Spirit is a project centered on mortality, the soul’s relationship to the body, and the space between life and death. Through large-scale sculptures and paintings inspired by the divine feminine, particularly Our Lady of Guadalupe, I explore feminine strength, vulnerability, and the interconnectedness of all things. I believe that my future as an artist is still uncertain but I am hopeful for a path that will enlighten me and lead me to beautiful things.
        </p>*/}

    </div>
  );
}
