import LayingPerson from "./static/images/drawings/laying-person.jpg"
import LayingWoman from "./static/images/drawings/laying-woman.jpg"
import SittingPerson from "./static/images/drawings/sitting-person.jpg"
import ThinkingPerson from "./static/images/drawings/thinking-person.jpg"
import Glitch from "./static/images/drawings/glitch.jpg"
import WomansFace from "./static/images/drawings/womans-face.jpg"
import Reburta from "./static/images/drawings/reburta.jpg"
import StudioLook from "./static/images/a-look-into-my-studio.jpg"

import WorkInProgress from "./static/images/paintings/wip-oil-paint.jpg"
import Mastercopy from "./static/images/paintings/mastercopy-oil-paint.jpg"

import ACloserLookToANativesPromise from "./static/images/paintings/a_closer_look_to_a_natives_promise.jpg"
import Absolute from "./static/images/paintings/absolute.jpg"
import CathyLine from "./static/images/paintings/cathy_line.jpg"
import Emanation from "./static/images/paintings/emanation.jpg"
import Essence from "./static/images/paintings/essence.jpg"
import HeatSignature from "./static/images/paintings/heat_signature.jpg"
import InTheStudio from "./static/images/paintings/in_the_studio.jpg"
import Nerves from "./static/images/paintings/nerves.jpg"
import Subsequence from "./static/images/paintings/subsequence.jpg"
import VirginDeGuadalupe from "./static/images/paintings/virgin_de_guadalupe.jpg"

import JohnHorn from "./static/images/drawings/john_horn.jpg"
import Untitled2 from "./static/images/drawings/untitled_2.jpg"
import UntitledGraphicCastDrawing from "./static/images/drawings/untitled_graphic_cast_drawing.jpg"

type ArtPiece = {
        src: string,
        width: number,
        height: number,
        title: string,
        medium: string,
        price: string,
        date: string,
        dimensions: string,
        description: string
      };

const paintings: ArtPiece[] = [
  {
    src: WorkInProgress,
    width: 25,
    height: 18,
    title: "Work in Progress",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2023",
    dimensions: "Unknown", 
    description: ""
  },
  {
    src: Absolute,
    width: 45,
    height: 18,
    title: "Absolute",
    medium: "Oil Paint on Trifold",
    price: "Not for sale",
    date: "2025",
    dimensions: "Unknown",
    description: "Work in progress. Thrifted the wooden trifoldl."
  },
  {
    src: VirginDeGuadalupe,
    width: 30,
    height: 18,
    title: "Virgin de Guadalupe",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2025",
    dimensions: "Unknown (Roughly 4ft tall)",
    description: "Work in progress"
  },
  {
    src: Mastercopy,
    width: 54,
    height: 18,
    title: "Mastercopy",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2023",
    dimensions: "Unknown", 
    description: ""
  },
  {
    src: Emanation,
    width: 30,
    height: 18,
    title: "Emanation",
    medium: "Eyeshadow Powder",
    price: "Not for sale",
    date: "2025",
    dimensions: "11in x 8.5in",
    description: ""
  },
  {
    src: HeatSignature,
    width: 30,
    height: 18,
    title: "Heat Signature",
    medium: "Eyeshadow Pigment & Colored Pencil",
    price: "Not for sale",
    date: "2025",
    dimensions: "16in x 10in",
    description: ""
  },
  {
    src: Essence,
    width: 30,
    height: 18,
    title: "A Master Copy",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2024",
    dimensions: "16in x 10in",
    description: ""
  },
  {
    src: CathyLine,
    width: 40,
    height: 18,
    title: "Cathy Lying",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2021",
    dimensions: "Unknown",
    description: ""
  },
  {
    src: ACloserLookToANativesPromise,
    width: 30,
    height: 18,
    title: "A Native's Promise",
    medium: "Oil Paint",
    price: "Not for sale",
    date: "2018",
    dimensions: "18in x 24in",
    description: ""
  },
  {
    src: Nerves,
    width: 18,
    height: 18,
    title: "Nerves",
    medium: "Pressed pigment sticks",
    price: "Not for sale",
    date: "2025",
    dimensions: "24in x 18in",
    description: ""
  },
  {
    src: InTheStudio,
    width: 35,
    height: 18,
    title: "Paintings in my Studio",
    medium: "N/A",
    price: "Not for sale",
    date: "2025",
    dimensions: "N/A",
    description: "Some work in progress paintings in my studio."
  },
  {
    src: Subsequence,
    width: 18,
    height: 18,
    title: "Subsequence",
    medium: "Oil Painting",
    price: "Not for sale",
    date: "2025",
    dimensions: "24in x 11in",
    description: ""
  },

];

const drawings: ArtPiece[] = [
  {
    src: StudioLook,
    width: 30,
    height: 20,
    title: "A look into my studio",
    medium: "Various",
    price: "Not for sale",
    date: "2023",
    dimensions: "Various", 
    description: "A look at several work in progress pieces currently in my studio"
  },
  {
    src: LayingPerson,
    width: 25,
    height: 18,
    title: "Laying Person",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
  {
    src: SittingPerson,
    width: 18,
    height: 15,
    title: "Sitting Person",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "15in x 18in", 
    description: ""
  },
  {
    src: ThinkingPerson,
    width: 25,
    height: 18,
    title: "Thinking Person",
    medium: "Chalk",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
  {
    src: LayingWoman,
    width: 25,
    height: 18,
    title: "Laying Woman",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
  {
    src: Glitch,
    width: 20,
    height: 18,
    title: "Glitch",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 20in", 
    description: ""
  },
  {
    src: WomansFace,
    width: 15,
    height: 11,
    title: "Portrait",
    medium: "Charcoal",
    price: "Not for sale",
    date: "2023",
    dimensions: "11in x 15in", 
    description: ""
  },
  {
    src: Reburta,
    width: 25,
    height: 18,
    title: "Reburta",
    medium: "Chalk",
    price: "Not for sale",
    date: "2023",
    dimensions: "18in x 25in", 
    description: ""
  },
  {
    src: JohnHorn,
    width: 25,
    height: 18,
    title: "John Horn Drawing",
    medium: "Graphite",
    price: "Not for sale",
    date: "2024",
    dimensions: "11in x 18in", 
    description: ""
  },
  {
    src: Untitled2,
    width: 30,
    height: 18,
    title: "Untitled",
    medium: "Graphite",
    price: "Not for sale",
    date: "2023",
    dimensions: "11in x 15in", 
    description: ""
  },
  {
    src: UntitledGraphicCastDrawing,
    width: 30, 
    height: 18,
    title: "Untitled Cast Drawing",
    medium: "Graphite",
    price: "Not for sale",
    date: "2024",
    dimensions: "10in x 16in", 
    description: ""
  },
];

export { paintings, drawings }
